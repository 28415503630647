<template>
    <div class="d-flex flex-column justify-center align-center fill-height">
        <v-card max-width="600">
            <v-card-title> <v-icon class="mr-1">account_circle</v-icon>Nuvolos account details</v-card-title>
            <v-card-text class="black--text">
                This is the first time you are accessing Nuvolos. Please provide some details to create a Nuvolos account associated with your university login:
                <v-form ref="form" v-model="valid" class="mt-3">
                    <v-text-field v-model="firstName" :counter="50" label="First Name" :rules="[rules.nonEmpty]" required></v-text-field>
                    <v-text-field v-model="lastName" :counter="50" label="Last Name" :rules="[rules.nonEmpty]" required></v-text-field>
                    <v-text-field v-model="email" label="E-mail" required readonly></v-text-field>
                </v-form>
                <div class="d-flex justify-center">
                    <v-btn color="success" :disabled="!valid" :loading="creatingAccount" @click="createAccount">
                        <v-icon>navigate_next</v-icon>Create Linked Account
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
        <v-btn text @click="showIntercomChat" color="secondary" small class="mt-4"><v-icon class="mr-1" small>support</v-icon>Ask for help</v-btn>
        <TheInvitationModal message="Creating account" :show="creatingAccount" />
    </div>
</template>
<script>
import { intercomMethods } from '@/mixins/intercom'
const TheInvitationModal = () => import('../components/TheInvitationModal')

export default {
    mixins: [intercomMethods],
    data() {
        return {
            creatingAccount: false,
            firstName: this.$auth.profile.given_name || '',
            lastName: this.$auth.profile.family_name || '',
            email: '',
            valid: false,
            rules: {
                nonEmpty: p => p.length > 0 || 'Please provide a value.'
            }
        }
    },
    mounted() {
        this.$axios.get(`/invitations/describe/${this.$route.params.invid}`).then(response => {
            this.$data.email = response.data.requestee_email
        })
        this.$axios.get('/users/current').then(response => {
            if (response.data.uid) {
                this.$router.push({
                    name: 'invitation-accept',
                    params: { invid: this.$route.params.invid }
                })
            }
        })
    },
    methods: {
        createAccount() {
            this.$data.creatingAccount = true
            this.$axios
                .post(
                    `/users/register_with_invitation/satosa/${this.$route.params.invid}`,
                    {
                        email: this.$data.email,
                        family_name: this.$data.lastName,
                        given_name: this.$data.firstName
                    },
                    { timeout: 300000 }
                )
                .then(() => {
                    this.$data.creatingAccount = false
                    this.$store.dispatch('getUserInfo')
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Account created successfully!',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'check_circle'
                    })
                    this.$router.push({
                        name: 'invitation-accept',
                        params: { invid: this.$route.params.invid }
                    })
                })
                .catch(error => {
                    if (!error.response || !error.response.data || error.response.data.code !== 'already_registering') {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Account creation failed, please try again later.',
                            snackBarTimeout: 10000,
                            snackBarIcon: 'error'
                        })
                    }
                })
                .finally(() => {
                    this.$data.creatingAccount = false
                })
        }
    },
    components: { TheInvitationModal }
}
</script>
